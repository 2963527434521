<template>
  <div class="d-flex-column align-center main">
    <div class="mw-400 input-underline">
      <el-select
          placeholder="Select member"
          v-model="selectVal"
          class="full-width">
        <el-option
            v-for="option in options"
            :key="option.value"
            :label="option.label"
            :value="option.value"/>
      </el-select>
    </div>

    <!--  TABLE START  -->
    <div class="table-wrapper d-flex">
      <div class="el-table">
        <table>
          <colgroup>
            <col name="col_1">
            <col name="col_2">
            <col name="col_3">
          </colgroup>

          <thead class="bg-transparent">
          <tr>
            <th colspan="1" rowspan="1" class="col_1 is-leaf">
              <div class="cell">Year</div>
            </th>
            <th colspan="1" rowspan="1" class="col_2 is-leaf">
              <div class="cell">Type</div>
            </th>
            <th colspan="1" rowspan="1" class="col_2 is-leaf">
              <div class="cell">Entity Type</div>
            </th>
            <th colspan="1" rowspan="1" class="col_3 is-leaf">
              <div class="cell">Entity Name</div>
            </th>
            <th colspan="1" rowspan="1" class="col_3 is-leaf">
              <div class="cell">Status</div>
            </th>
          </tr>
          </thead>

          <tbody>
          <tr
              v-for="(row, index) in tableData"
              :key="index"
              class="el-table__row"
          >
            <td
                v-for="(col, i) in row"
                :key="i"
            >
              <div class="cell">{{ col }}</div>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>

    <p>Need to register for the upcoming season?</p>
    <el-button
        @click="$router.push({name: 'regotype'})"
        type="primary" class="full-width mw-400">
      Register
    </el-button>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapMutations } from 'vuex';

import msg from '@/utils/constants/msg';
import endpoints, { endpointParams } from '@/utils/constants/api';

export default {
  name: 'registrationhistory',
  mounted() {
    this.LOADING(true);
    this.$http.get(endpoints.getMembers)
      .then((membersResponse) => {
        const options = membersResponse.data.data.map(({ _id: value, profile }) => (
          {
            label: `${profile.firstName} ${profile.lastName}`,
            value,
          }
        ));
        this.$set(this, 'options', options);
        this.LOADING(false);
      })
      .catch(() => {
        this.notify({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: 'warning',
          window,
        });
        this.LOADING(false);
      });
  },
  watch: {
    selectVal() {
      this.getRegistrationHistory(this.selectVal);
    },
  },
  methods: {
    ...mapActions('views', ['notify']),
    ...mapMutations('root', ['LOADING']),
    async getRegistrationHistory(id) {
      if (!id) {
        this.$set(this, 'tableData', []);
        return;
      }
      this.LOADING(true);
      try {
        const res = await this.$http.get(endpointParams.profile.registrationHistory(id));
        const { data } = res.data;
        const result = data.map(
          line => ({
            year: line.season,
            type: line.memberType.charAt(0).toUpperCase() + line.memberType.slice(1),
            entityType: _.upperFirst(line.entityType),
            association: line.entityName,
            expired: line.expired ? "Inactive" : "Active"
          }),
        );
        this.$set(this, 'tableData', result);
      } catch (error) {
        this.notify({
          msg: error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : msg.error.apiError,
          route: this.$route.name,
          type: 'warning',
          window,
        });
      }
      this.LOADING(false);
    },
  },

  data() {
    return {
      options: [],
      selectVal: '',
      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
  .el-table {
    overflow-x: auto;
    background-color: transparent;

    .col_1 {
      min-width: 40px;
    }

    .col_2 {
      min-width: 60px;
    }

    .col_3 {
      min-width: 100px;
    }

    .cell {
      font-size: .9em;
      white-space: nowrap;
      text-align: center;
    }

    table {
      border-collapse: collapse;
      width: 100%;

      thead {
        tr, th {
          background-color: transparent;
        }
      }

      tbody {
        tr {
          background-color: white;
        }
      }
    }
  }

  .bg-transparent {
    background-color: transparent !important;
  }
</style>
